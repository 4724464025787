import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import logo from '../../images/sagov.png'

import Link from '../link'
import NavItem from '../nav-item'

import { navigate  } from '@reach/router'

export default () => {
  const { footerLeft, footerCentre, languages } = useStaticQuery(graphql`
    {
      footerLeft: contentfulNavigationMenu(slug: {eq: "footer-left"}) {
        ...MenuFragment
      }
      footerCentre: contentfulNavigationMenu(slug: {eq: "footer-centre"}) {
        ...MenuFragment
      }
      languages: contentfulNavigationMenu(slug: {eq: "languages"}) {
        ...MenuFragment
      }
    }
  `)

  return (
    <footer className="footer__wrapper" id="footer__wrapper">
      <div className="footer">
        <div className="footer__container">
          <header className="footer__header">
            <div className="footer__logos">
              <Link href="#" className="footer__logo footer__logo--sa">
                <img src={logo} alt="" style={{ maxWidth: 300 }} />
              </Link>
            </div>
          </header>
          <footer className="footer__footer">
            <div className="footer__lang">
              <select className="footer__lang-selector" onChange={(e) => {
                const href = e.currentTarget.selectedOptions[0].getAttribute('href')

                if (href) {
                  navigate(href)
                }
              }}>
                {languages.items.map((lang, i) => (
                  <option href={lang.link} key={i}>{lang.title}</option>
                ))}
              </select>
            </div>
            <nav className="footer__subnav">
              <ul className="footer__subnav-list">
                {footerCentre.items.map((item, i) => (
                  <NavItem title={item.title} href={item.link} key={i} />
                ))}
              </ul>
            </nav>
            <div className="footer__colophon">
              <nav className="footer__colophon-nav">
                <ul className="footer__colophon-nav-list">
                  {footerLeft.items.map((item, i) => (
                    <NavItem title={item.title} href={item.link} key={i} />
                  ))}
                </ul>
              </nav>
            </div>
          </footer>
        </div>
      </div>
    </footer>
  )
}

export const menuFragment = graphql`
  fragment MenuFragment on ContentfulNavigationMenu {
    items {
      ... on ContentfulNavItemExternal {
        title
        link
      }
    }
  }
`

