import { graphql, useStaticQuery } from 'gatsby'

export default () => useStaticQuery(
  graphql`
    {
      menu: contentfulNavigationMenu(slug: {eq: "header-navigation"}) {
        items {
          ... on ContentfulNavItemParent {
            ...NavItemParent
          }
        }
      }
    }
  `
).menu.items
