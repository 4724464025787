import React, { Component } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from "gatsby"
import Link from '../link'

export default class Banner extends Component {
  static defaultProps = {
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    bannerPosition: 'Middle Left',
    preTitle: null,
    title: null,
    fullHeight: false,
    link: null,
  }

  get classes() {
    const classes = ['banner', this.titlePositionClass]

    classes.push(this.props.fullHeight ? 'banner--full-height' : 'banner--standard-height')

    return classes.join(' ')
  }

  get backgroundPosition() {
    const { backgroundPositionX, backgroundPositionY } = this.props

    return [
      backgroundPositionX || Banner.defaultProps.backgroundPositionX,
      backgroundPositionY || Banner.defaultProps.backgroundPositionY,
    ].join(' ')
  }

  get titlePositionClass() {
    let [y, x] = (this.props.bannerPosition || Banner.defaultProps.bannerPosition).toLowerCase().split(' ')

    return `banner--x-${x} banner--y-${y}`
  }

  get background() {
    return this.props?.background?.localFile?.childImageSharp?.fluid
  }

  render() {
    const { link, fontScale } = this.props

    return (
      <div className="banner__wrapper">
        <div className={this.classes}>
          <BackgroundImage style={{
            backgroundPosition: this.backgroundPosition,
          }} fluid={this.background}>
            <div className="banner__container">
              <div className="banner__content">
                <h1 className="banner__title-wrapper" style={{ fontSize: `${fontScale || 1}rem` }}>
                  {this.props.preTitle ? (
                    <span className="banner__subtitle">{this.props.preTitle}</span>
                  ) : ''}
                  {this.props.title ? (
                    <strong className="banner__title">{this.props.title}</strong>
                  ) : ''}
                </h1>
                {link && link.link && link.title ? (
                  <Link className="banner__button" href={link.link}>{link.title}</Link>
                ) : ''}
              </div>
            </div>
          </BackgroundImage>
        </div>
      </div>
    )
  }
}

export const query = graphql`
  fragment BannerFragment on ContentfulBanner {
    backgroundPositionX
    backgroundPositionY
    bannerPosition
    background {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    preTitle
    title
    fontScale
    fullHeight
    link {
      title
      link
    }
  }
`
