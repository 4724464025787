import React, { Component } from 'react'
import Link from '../link'

export default class NavItem extends Component {
  static defaultProps = {
    href: '#',
    title: '',
    slug: '',
    classList: [],
    active: false,
    type: 'default',
    onClick: null,
  }

  get classes() {
    const classes = this.props.classList.concat(['nav-item'])

    if (this.props.children) {
      classes.push('dropdown')
    }

    if (this.props.active) {
      classes.push('active')
    }

    return classes.join(' ')
  }

  get href() {
    let href = this.props.href

    if (this.props.link && this.props.link.slug) {
      href = `/${this.props.link.slug}`
    }

    return href
  }

  render() {
    let onClick = this.props.onClick

    return (
      <li data-slug={this.props.slug} className={this.classes}>
        <Link className="nav-link" href={this.href} onClick={() => onClick ? onClick(this) : null}>
          <span>{this.props.title}</span>
        </Link>
        {this.props.children}
      </li>
    )
  }
}
