import React, { Component } from 'react'

export default class Link extends Component {
  static defaultProps = {
    href: '#',
    onClick: null,
  }

  get externalProps() {
    return this.props.href.match(/^#/) ? {} : {
      rel: 'noopener',
      target: '_blank',
    }
  }

  get isInternal() {
    return !!(
      this.props.href.match(/^\/(?!\/)/)
    )
  }

  render() {
    const { href, children, onClick, alwaysOpen, ...props } = this.props

    if (typeof onClick === 'function') {
      props.onClick = onClick
    }

    return this.isInternal && !this.onClick ? (
      <a href={href} {...props}>{children}</a>
    ) : (
      <a href={href} {...{ ...this.externalProps, ...props }}>{children}</a>
    )
  }
}
