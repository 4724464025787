import { graphql, useStaticQuery } from 'gatsby'

export default () => useStaticQuery(
  graphql`
    {
      menu: contentfulNavigationMenu(slug: {eq: "slideout-menu"}) {
        items {
          ... on ContentfulNavItemParent {
            ... NavItemParent
          }
          ... on ContentfulNavItemExternal {
            ...NavItemExternal
          }
        }
      }
    }
  `
).menu.items
