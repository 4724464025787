import React, { Component } from 'react'

import CloseIcon from '../../images/close.inline.svg'
import { slideoutMenu } from '../../lib/navigation'
import NavItem from '../nav-item'

class SlideoutMenu extends Component {
  static defaultProps = {
    activeItem: null,
    navigate: (id) => console.log(id),
  }

  constructor(props) {
    super(props)

    this.state = {
      open: props.show,
    }
  }

  get wrapperClasses() {
    const wrapperClasses = ['slideout-menu__wrapper']

    if (this.state.open) {
      wrapperClasses.push('show')
    }

    return wrapperClasses.join(' ')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show === true && this.props.show === false) {
      // We're closing, transition appropriately
      const close = () => {
        this.setState({
          open: false,
        })

        this.wrapper.removeEventListener('transitionend', close)
      }

      this.wrapper.addEventListener('transitionend', close)
    } else if (prevProps.show !== this.props.show) {
      // We're just changing state, continue
      this.setState({
        open: this.props.show,
      })
    }
  }

  render() {
    return (
      <div ref={(ref) => this.wrapper = ref} className={this.wrapperClasses}>
        <div className="slideout-menu">
          <div className="slideout-menu__container">
            <header className="slideout-menu__header">
              <button type="button" className="slideout-menu__toggle" onClick={this.props.onClose} aria-controls="primary-navigation" aria-expanded="true" aria-label="Toggle navigation">
                <CloseIcon className='slideout-menu__toggle-icon' />
              </button>
            </header>
            <nav className="slideout-menu__nav" id="primary-navigation">
              {this.props.navItems ? (
                <ul className="navbar-nav slideout-menu__list">
                  {this.props.navItems.map(({ __typename, subtitle, ...item }) => (
                    <NavItem onClick={() => {
                      if (__typename === 'ContentfulNavItemParent') {
                        this.props.navigate(item.id)
                      }
                    }} active={item.id === this.props.activeItem} key={item.id} type={__typename} {...item}>
                      {__typename === 'ContentfulNavItemParent' && item.items && item.items.length > 0 ? (
                        <div className="dropdown-menu">
                          <div className="dropdown-menu__content">
                            <div className="nav-left">
                              {subtitle ? <h3 className="dropdown-title">{subtitle}</h3> : ''}

                              <ul className="dropdown-menu__list">
                                {item.items.map(({ __typename, ...item }) => (
                                  <NavItem key={item.id} type={__typename} {...item} />
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                    </NavItem>
                  ))}
                </ul>
              ) : ''}
            </nav>
          </div>
        </div>
      </div>
    )
  }
}

export default props => <SlideoutMenu {...props} navItems={slideoutMenu()} />
