import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// Import SA.com styles
import '../../styles/main.scss'

import Analytics from '../analytics'
import Header from "../header"
import Footer from '../footer'

const Layout = ({ children, banner }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const aside = null
  const preFooter = null

  return (
    <>
      <Analytics />

      <a href="#main" className="skip-to-content" tabIndex="1">Skip to content</a>

      <Header siteTitle={data.site.siteMetadata.title} />

      <div className="stage-2-content">
        {banner}

        <div className="content-wrapper" itemScope>
          <main className="main" id="main">
            {children}
          </main>

          {aside ? aside : ''}
        </div>

        <script>document.body.classList.add('stage-1-loaded')</script>

        {preFooter ? preFooter : ''}

        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
